.buttonError {
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  color: #ffffff;
  width: 100%;
  height: 56px;
  background: #f83a3a;
  border: none;
  cursor: pointer;
  margin: 0;
  font-family: 'Nekst';
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.back {
  background: #000000;
}

@media (min-width: 600px) {
  .buttonError {
    font-size: 24px;
  }
}

// SKILLIUM DESIGN

:global(body.skilliumDesign) {
  .buttonError {
    color: var(--dark);
    background: var(--action-color);
    height: 44px;
    font-family: Rubik;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 120% */
    letter-spacing: -0.8px;
    text-transform: uppercase;
  }
}
